import React from "react";
import { ProtHeader } from "./protHeader";
import { useEffect } from "react";
export const ProtPlayerAward = (props) =>
{
    useEffect(() => {
        props.CBcolor("#0E2DBE");
    }, []);
    return(
    <div id="game">
    <ProtHeader/> 
<img src = {props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<div className ="divtext_prot"><b>{props.prName}</b></div><React.Fragment><br/></React.Fragment>
<div className ="divtext_prot">Насколько честными были решения судьи?</div><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\playeraward\\1");}}>1</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\playeraward\\2");}}>2</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\playeraward\\3");}}>3</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\playeraward\\4");}}>4</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\playeraward\\5");}}>5</button><React.Fragment><br/></React.Fragment>
    </div>
    );
}