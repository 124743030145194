import React, {useState, useEffect} from 'react';
//import useWebSocket from 'react-use-websocket';
import useWebSocket from 'react-use-websocket';
//import io from 'socket.io-client';





// PROTESTATION SCREENS ------------------------------------------------------------------------------
import { ProtLobbyScreen } from './protLobby';
import { ProtLobbyTutorialScreen } from './protLobby_tutorial';
import { ProtThemeChoose } from './protLobby_themes';
import { ProtLectorScreen } from './protLobby_lector';
import { ProtPlayerScreen } from './protPlayerScreen';
import { ProtJudgeAwait } from './protJudge_wait';
import { ProtJudgeChoose } from './protJudge_choose';
import { ProtPlayerAward } from './protPlayerAward';
// ---------------------------------------------------------------------------------------------------



 const socketUrl = 'wss://cyberbox.trgu.ru/api/';



export const ProtMainGame = (props) =>
{
const [returnstring, setreturnstring] = useState("");
const [GScreen, setGScreen] = useState(0);
const [protThemes, setprotThemes] = useState([]);
const [protLastMsg, setprotLastMsg] = useState("");
const [protTheme, setprotTheme] = useState("");
const [GNotStarted, setGNotStarted] = useState(false);
const [protGamePoints, setprotGamePoints] = useState(0);
useEffect(() => {
    setGScreen(0);//-------------------------------------------------------------------------------ЭКРАНЫ
    setprotThemes(["Вредные привычки","Тренды","Детство"]);
    setprotTheme("Тренды");
  }, []);

const handleProtMessage = (Wmsg) =>
{
  console.log("protestation raw + " + Wmsg); 
  if(Wmsg.includes("\\"))
  {
  var msg = Wmsg.split("\\");
  console.log("protestation + " + msg);
  switch (msg[1])
  {
    case "setProtPoints":
      setprotGamePoints(msg[2]);
    break;
    case "startscreen":
    setGNotStarted(true);
    setGScreen(0);
    break;
    case "tutchoose":
    setGScreen(1);
    break;
    case "themes":
      setprotThemes([msg[2],msg[3],msg[4]]);
      setGScreen(2);
    break;
    case "LectorScreen":
      console.log(msg);
      if((msg[2] != "") && (msg[2] != undefined))
      {
        setprotTheme (msg[2]);
        setGScreen(3);
      }
      else
      setGScreen(3);
      break;
    case "JudgeScreen":
      setGScreen(5);
      break;
    case "PlayerScreen":
      setGScreen(4);
      break;
    case "JudgeChoose":
      setGScreen(6);
      break;
    case "PlayerAward":
      setGScreen(7);
      break;
  }
}
}
/*
if(protLastMsg != props.WSMessage)
{
console.log(typeof WSMessage);
setprotLastMsg(props.WSMessage);
handleProtMessage(props.WSMessage);
}
*/
useEffect(() => {
  handleProtMessage(props.WSMessage);
}, 
[props.WSMessage]);
/*
GameStates
|| Протестейшн
0 - (Протестейшн) Лобби протестейшна
1 - (Протестейшн) Туториал протестейшна
2 - (Протестейшн) Выбор темы протестейшна
3 - (Протестейшн) Экран лектора протестейшна
4 - (Протестейшн) Экран игрока протестейшна
5 - (Протестейшн) Экран судьи протестейшна
6 - (Протестейшн) Экран принятия протеста
7 - (Протестейшн) Экран оценки судьи
*/
useEffect(() => {
    switch (GScreen)
    {
    case 0:
    setreturnstring(<ProtLobbyScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints}/>);
    break;
    case 1:
    setreturnstring(<ProtLobbyTutorialScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints}/>);
    break;
    case 2:
    setreturnstring(<ProtThemeChoose PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} themes = {protThemes} settheme = {setprotTheme}/>);
    break;
    case 3:
    setreturnstring(<ProtLectorScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} theme = {protTheme}/>);
    break;
    case 4:
    setreturnstring(<ProtPlayerScreen PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} />);
    break;
    case 5:
    setreturnstring(<ProtJudgeAwait PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} />);
    break;
    case 6:
    setreturnstring(<ProtJudgeChoose PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} />);
    break;
    case 7:
    setreturnstring(<ProtPlayerAward PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} />);
    break;
    } 
  }, [GScreen, protGamePoints]);
  return(
    returnstring
    );
}