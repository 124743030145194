import React, {useEffect, useState, useRef}from "react";
import { useCookies } from 'react-cookie';

const regex = new RegExp('^[A-Z]{1,4}$');
const regex2 = new RegExp('^[a-z]$');
const ForbSymb = ["|", "\\"];

export const LoginForm = (props) => {
    const refName = useRef(null);
    const refRCode = useRef(null);
    const [RoomCode, setRoomCode] = useState("");
    const [PlayerName, setPlayerName] = useState("");
    const [cookies, setcookie, removecookie] = useCookies(['nickname', 'lastpid', 'lastrcode, playerplace, playercolor']);
    useEffect(() => {
        const elementName = refName.current;
        //console.log(element);
        if(cookies['nickname'] !== undefined) {elementName.value = cookies['nickname']; setPlayerName(cookies['nickname']);}
    }, []);
    const getRoom = (globState) => {
        console.log("Имя - " + globState["PlayerName"] + " Код - " + globState["RoomCode"]);
    }
    return(
    <form>
    <input className="FormInput" placeholder="Код комнаты" ref={refRCode} onChange={(e) => 
        {
            e.target.value = e.target.value.toUpperCase();
            if(!regex.test(e.target.value)) e.target.value = e.target.value.slice(0, -1);
            setRoomCode(e.target.value);
        }
    }
    onKeyDown={(e) =>{if(e.key === "Enter") props.ChangeRCode(RoomCode, PlayerName);}}
    ></input>
    <br className="BrClass"/>
    <input className="FormInput" placeholder="Имя игрока" ref={refName}  onChange={(e) => 
        {
            if(e.target.value.length > 12) e.target.value = e.target.value.slice(0, -1);
            if(ForbSymb.includes(e.target.value[e.target.value.length-1])) e.target.value = e.target.value.slice(0, -1);
            setPlayerName(e.target.value);
        }
    }
    onKeyDown={(e) =>{if(e.key === "Enter") 
    {
        props.ChangeRCode(RoomCode, PlayerName);
    }
    }}
    ></input>
    <br />
    <div className="SubmitButton">
    <button type="button"  onClick={(e) => 
        {
            const elementCode = refRCode.current;
            elementCode.value = "";
            props.ChangeRCode(RoomCode, PlayerName);
        }}>Вход</button>
    </div>
    </form>
        );
}