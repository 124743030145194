import React from "react";
import { useEffect } from "react";
export const IsusVoting = (props) =>
{
    useEffect(() => {
        props.CBcolor("#FFFFFF");
        document.body.style.height = "98vh";
        console.log() 
    }, []);
    useEffect(() => {
    console.log(props.buttonCount);
    if (props.buttonCount >= 1) {document.getElementById("b1").style.display = 'block';} else document.getElementById("b1").style.display = 'none';
    if (props.buttonCount >= 2) {document.getElementById("b2").style.display = 'block';} else document.getElementById("b2").style.display = 'none';
    if (props.buttonCount >= 3) {document.getElementById("b3").style.display = 'block';} else document.getElementById("b3").style.display = 'none'; 
    if (props.buttonCount >= 4) {document.getElementById("b4").style.display = 'block';} else document.getElementById("b4").style.display = 'none';
    if (props.buttonCount == 5) {document.getElementById("b5").style.display = 'block';} else document.getElementById("b5").style.display = 'none';}
    , [props])
    return(
        <div id="game" className={props.isChar2}>
        <link href="https://trgu.ru/Cyberbox 20775_files/main.bd4d5666.css" rel="stylesheet"></link>
        <div className="bg">
        <img src="https://trgu.ru/assets/bg.png"></img>
        <img src="https://trgu.ru/assets/bg4.png" className="bg4"></img>
        </div>

        <div className="blur mewhenopinion" style = {{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <div className="character-header">
<h1 id="slov_header">Вы: </h1> 
<div className="avatatar"></div> 
</div>
                        <h2>Выбирай, сука</h2>
                        <button type="button" id="b1" onClick={() =>{props.sendMessage("sendtoserver|isus\\vote\\1");}}>1</button>
                        <button type="button" id="b2" onClick={() =>{props.sendMessage("sendtoserver|isus\\vote\\2");}}>2</button>
                        <button type="button" id="b3" onClick={() =>{props.sendMessage("sendtoserver|isus\\vote\\3");}}>3</button>
                        <button type="button" id="b4" onClick={() =>{props.sendMessage("sendtoserver|isus\\vote\\4");}}>4</button>
                        <button type="button" id="b5" onClick={() =>{props.sendMessage("sendtoserver|isus\\vote\\5");}}>5</button>
                    </div>
                    <br />
        </div>
    );
}