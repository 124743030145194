import React, {useState, useEffect} from 'react';
//import useWebSocket from 'react-use-websocket';
import useWebSocket from 'react-use-websocket';
//import io from 'socket.io-client';





// PROTESTATION SCREENS ------------------------------------------------------------------------------
import { IsusPlayer } from './isusPlayer';
import { IsusVoting } from './isusVoting';
import { IsusLobby } from './isusLobby';
// ---------------------------------------------------------------------------------------------------

 const socketUrl = 'wss://cyberbox.trgu.ru/api/';

export const IsusMainGame = (props) =>
{
const [returnstring, setreturnstring] = useState("");
const [GScreen, setGScreen] = useState(0);
const [protThemes, setprotThemes] = useState([]);
const [protLastMsg, setprotLastMsg] = useState("");
const [protTheme, setprotTheme] = useState("");
const [GNotStarted, setGNotStarted] = useState(false);
const [protGamePoints, setprotGamePoints] = useState(0);
const [ChoiceButtonsCount, setChoiceButtonsCount] = useState(1);
const [isCharc, setisChar] = useState("noone");
const [abilDisabled, setabilDisabled] = useState(false);

function setChar(lutergnome){
  //document.getElementById("game").setAttribute("class", lutergnome)
  setisChar(lutergnome)
  console.log(isCharc)
} 
function setBar(lutergnome, lutergnome2){
  if(lutergnome == "health"){
  document.getElementById("health").setAttribute("class", "health-bar LutersAss" + (lutergnome2 + 1))
  if((lutergnome2) == 0){
      document.getElementById("hi").setAttribute("style", "animation: hideIconBar 1s forwards")
  }}
  else {
      document.getElementById("mana").setAttribute("class", "mana-bar LuterAss" + lutergnome2)
      if((lutergnome2) == 0){
          document.getElementById("mi").setAttribute("style", "animation: hideIconBar 1s forwards; transform: scale(0%)")
      }
      else{
          document.getElementById("mi").setAttribute("style", "animation: showIconBar 1s forwards")
      }
  }
}



useEffect(() => {
    setGScreen(0);//-------------------------------------------------------------------------------ЭКРАНЫ
  }, []);
const handleIsUsMessage = (Wmsg) =>
{
  console.log("isus raw + " + Wmsg); 
  if(Wmsg.includes("\\"))
  {
  var msg = Wmsg.split("\\");
  console.log("isus + " + msg);
  switch (msg[1])
  {
    case "updateMana":
      setBar("mana", Number(msg[2]))
    break;
    case "updateHealth":
      setBar("health", Number(msg[2]))
    case "setChar":
      setChar(msg[2]);
    break;
    case "EnableAbility":
      setabilDisabled(false);
    break;
    case "DisableAbility":
      setabilDisabled(true);
    break;
    case "emptyscreen":
    setGScreen(0);
    break;
    case "choicescreen":
      setChoiceButtonsCount(Number(msg[2]));
    setGScreen(1);
    break;
    case "attackscreen":
    setGScreen(2);
    break;
  }
}
}
/*
if(protLastMsg != props.WSMessage)
{
console.log(typeof WSMessage);
setprotLastMsg(props.WSMessage);
handleProtMessage(props.WSMessage);
}
*/
useEffect(() => {
  handleIsUsMessage(props.WSMessage);
}, 
[props.WSMessage]);
/*
GameStates
|| Протестейшн
0 - (Протестейшн) Лобби протестейшна
1 - (Протестейшн) Туториал протестейшна
2 - (Протестейшн) Выбор темы протестейшна
3 - (Протестейшн) Экран лектора протестейшна
4 - (Протестейшн) Экран игрока протестейшна
5 - (Протестейшн) Экран судьи протестейшна
6 - (Протестейшн) Экран принятия протеста
7 - (Протестейшн) Экран оценки судьи
*/
useEffect(() => {
    switch (GScreen)
    {
    case 0:
    setreturnstring(<IsusLobby PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} isChar2 = {isCharc}/>);
    break;
    case 1:
    setreturnstring(<IsusVoting PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} buttonCount = {ChoiceButtonsCount} handleIsUsMessage = {handleIsUsMessage} isChar2 = {isCharc}/>);
    break;
    case 2:
    setreturnstring(<IsusPlayer PColors = {props.PColor}  fsetPingt = {props.setPingt} prName = {props.PName} prCode = {props.RCode}  CBcolor = {props.ChangeBColor} sendMessage = {props.sendMessage} protGamePoints = {protGamePoints} isChar2 = {isCharc} abilDisabled={abilDisabled} setabilDisabled = {setabilDisabled}/>);
    break;
    } 
  }, [GScreen, ChoiceButtonsCount, isCharc, abilDisabled]);
  return(
    returnstring
    );
}