import React, {useState, useEffect} from "react";
import { Header } from "../header";
import { TestButton } from "./testbutton";
export const ButtonsScreen = (props) =>
{
    const [returnstring, setreturnstring] = useState([]);
    const basicString = "";
    console.log(props.TestButtons);
    useEffect(() => {
    setreturnstring([]);
    var rtrns = [];
    for (var i = 0; i < props.TestButtons.length; i++)
    {
       //"btn" + i.toString()
        rtrns.push(<TestButton key = {"btn" + i.toString()} bid = {i.toString()} ButtonName = {props.TestButtons[i]}  sendMessage = {props.sendMessage}/>);
       // rtrns.push(<br key = {"br" + i.toString()} />)
        console.log(rtrns);
    } 
    setreturnstring(
        <div id="game">
        <Header/>
    <div className ="divtext"><b>{props.PName}</b></div>
    <img src = {props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/lobby/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
    <div className ="divtext"><b>{props.TestText}</b><br /></div>
    {rtrns}
       </div>);
    }, [props]);
    return(returnstring);
}