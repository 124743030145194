import React from "react";
import { ProtHeader } from "./protHeader";
import { useEffect } from "react";
const ForbSymb = ["|", "\\"];
var ThemeCustom = ""; 

export const ProtThemeChoose = (props) =>
{ 
    
    useEffect(() => {
        props.CBcolor("#0E2DBE");
    }, []);
    return(
    <div id="game">
    <ProtHeader/> 
<img src = {props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<div className ="divtext_prot"><b>{props.prName}</b></div><React.Fragment><br/></React.Fragment>
<div className ="divtext_prot"><b>{props.protGamePoints}</b> очков</div><React.Fragment><br/></React.Fragment>
<div className ="divtext_prot">Выберите тему для обсуждения:</div><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\choosetheme\\" + props.themes[0]); props.settheme(props.themes[0]);}}>{props.themes[0]}</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\choosetheme\\" + props.themes[1]); props.settheme(props.themes[1]);}}>{props.themes[1]}</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\choosetheme\\" + props.themes[2]); props.settheme(props.themes[2]);}}>{props.themes[2]}</button><React.Fragment><br/><br/></React.Fragment>
<div className ="divtext_prot">Или введите свою:</div><React.Fragment><br/></React.Fragment>
<input className="FormInput" placeholder="Тема" onChange={(e) => 
        {
            if(e.target.value.length > 25) e.target.value = e.target.value.slice(0, -1);
            if(ForbSymb.includes(e.target.value[e.target.value.length-1])) e.target.value = e.target.value.slice(0, -1);
            ThemeCustom = e.target.value;
        }
    }
    onKeyDown={(e) =>{if(e.key == "Enter") props.sendMessage("sendtoserver|prot\\choosetheme\\" + ThemeCustom); props.settheme(ThemeCustom);}}></input>
<button type="button"  onClick={() =>{props.sendMessage("sendtoserver|prot\\choosetheme\\" + ThemeCustom);}}>Отправить</button><React.Fragment><br/></React.Fragment>
    </div>
    );
}