import React from "react";
import { ProtHeader } from "./protHeader";
import { useEffect } from "react";
export const ProtLobbyScreen = (props) =>
{
    useEffect(() => {
        props.CBcolor("#0E2DBE");
    }, []);
    return(
    <div id="game">
    <ProtHeader/> 
<img src = {props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/prot/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<div className ="divtext_prot"><b>{props.prName}</b></div><React.Fragment><br/></React.Fragment>
<div className ="divtext_prot"><b>{props.protGamePoints}</b> очков</div><React.Fragment><br/></React.Fragment>
    </div>
    );
}