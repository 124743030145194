import React, {useState, useEffect} from 'react';
import { Header } from './header';
export const TestPanel = (props) =>
{
const [msg, setmsg] = useState("");
return(
<div id="game">
<Header />
<div className ="divtext">Код комнаты:<b>{props.prCode}</b></div>
<div className ="divtext">Никнейм: <b>{props.prName}</b></div>
<div className ="divtext">Аватарка:</div>
<img src = {props.PColors !== undefined ? "https://cyberbox.trgu.ru/public/avatars/lobby/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<input className="FormInput" placeholder="Сообщение" onChange={(e) => setmsg(e.target.value)}></input><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={(e) =>{props.sendMessageW(msg); console.log(msg);}}>Отправить</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() => {props.CBcolor("white")}}>Cменить на белый</button><React.Fragment><br/></React.Fragment>
<button type="button"  onClick={() => {props.CBcolor("black")}}>Cменить на чёрный</button>
<div className ="divtext">Собщения: <br/> <b>{props.LastMsg}</b></div>
</div>
);
}