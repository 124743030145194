import React from "react";
import { useEffect } from "react";
export const IsusLobby = (props) =>
{
    
    useEffect(() => {
        props.CBcolor("#FFFFFF");
        document.body.style.height = "98vh";
    }, []);
    return(
        <div id="game" className={props.isChar2}>
        <link href="https://trgu.ru/Cyberbox 20775_files/main.bd4d5666.css" rel="stylesheet"></link>
        <div className="bg">
        <img src="https://trgu.ru/assets/bg.png"></img>
        </div>

        <div className="blur">
<div className="character-header">
<h1 id="slov_header">Вы: </h1> 
<div className="avatatar"></div>
</div>
</div>
</div>
    );
}