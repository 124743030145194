import React from "react";
import { LoginForm } from "./loginform_testpage";

//import "../css/firstlogin.css"

export class TestPageLogin extends React.Component {
    constructor(props)
    {
        super(props);
        console.log("123");
        props.setDebug(true);
    }
    
    render() {
    return (
    <div id = "mainForm">
    <h1 id = "cb_header">Cyberbox TestPage</h1>
    <LoginForm ChangeRCode = {this.props.PropchangeRCode}/>
    </div>
    );
    }

}