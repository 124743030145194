import React from "react";
import { ProtHeader } from "./Protestation/protHeader";
import { useEffect } from "react";
export const MirkoiButton = (props) =>
{
    useEffect(() => {
        props.ChangeBColor("#000000");
    }, []);
    return(
    <div id="game">
    <h1 id = "cb_header">Короли Вечеринок</h1>
<img src = {props.PColor != undefined ? "https://cyberbox.trgu.ru/public/avatars/lobby/PlayerMain" + (props.PColor + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<div className ="divtext"><b>{props.PName}</b></div>
<React.Fragment><br/></React.Fragment>
<img src={"https://cyberbox.trgu.ru/public/button.png"} alt="Кнопка(Ну типа)" width="128" height="128"  onClick={() =>{props.sendMessage("sendtoserver|cbx\\button")}} /><React.Fragment><br/></React.Fragment>
   </div> 
    );
}