/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { LoginForm_Code } from "./loginform_code";
import { useParams } from "react-router-dom";

//import "../css/firstlogin.css"

export const FirstLogin_Code = (props) =>
{
    let { roomcode } = useParams();
    console.log(roomcode);
        return (
        <div id = "mainForm">
        <h1 id = "cb_header">Cyberbox Test</h1>
        <LoginForm_Code ChangeRCode = {props.PropchangeRCode} RCode = {roomcode}/>
        </div>
        );
}

/*
export class FirstLogin_Code extends React.Component {
    constructor(props)
    {
        super(props);
    }
    render() {
    return (
    <div id = "mainForm">
    <h1 id = "cb_header">Cyberbox Test</h1>
    <LoginForm ChangeRCode = {this.props.PropchangeRCode} RCode = {this.props.PropRCode}/>
    </div>
    )
    }

}
*/