import React from "react"; 

const regex = new RegExp('^[A-Z]{1,4}$');
const regex2 = new RegExp('^[a-z]$');
const ForbSymb = ["|", "\\"];

export class LoginForm extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            RoomCode: "",
            PlayerName: ""
        }
        this.checkRoom = this.checkRoom.bind(this);
        this.getroom = this.getRoom.bind(this);
        this.cangeRCode = this.props.ChangeRCode.bind(this);
    }
    render() {
        return(
    <form>
    <input className="FormInput" placeholder="Код комнаты" onChange={(e) => 
        {
            e.target.value = e.target.value.toUpperCase();
            if(!regex.test(e.target.value)) e.target.value = e.target.value.slice(0, -1);
            this.setState({RoomCode: e.target.value})
        }
    }
    onKeyDown={(e) =>{if(e.key == "Enter") this.props.ChangeRCode(this.state.RoomCode, this.state.PlayerName);}}
    ></input>
    <br className="BrClass"/>
    <input className="FormInput" placeholder="Имя игрока"   onChange={(e) => 
        {
            if(e.target.value.length > 12) e.target.value = e.target.value.slice(0, -1);
            if(ForbSymb.includes(e.target.value[e.target.value.length-1])) e.target.value = e.target.value.slice(0, -1);
            this.setState({PlayerName: e.target.value})
        }
    }
    onKeyDown={(e) =>{if(e.key == "Enter") this.props.ChangeRCode(this.state.RoomCode, this.state.PlayerName);}}
    ></input>
    <br />
    <div className="SubmitButton">
    <button type="button"  onClick={(e) => {this.props.ChangeRCode(this.state.RoomCode, this.state.PlayerName);}}>Вход</button>
    </div>
    </form>
        );
    }
    getRoom(globState){
        console.log("Имя - " + globState["PlayerName"] + " Код - " + globState["RoomCode"]);
    }
    checkRoom(roomStats){
        return this.state;
    }
}