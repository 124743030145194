import React from "react";
import { LoginForm } from "./loginform";

//import "../css/firstlogin.css"

export class FirstLogin extends React.Component {
    constructor(props)
    {
        super(props);
        console.log("123");
    }
    
    render() {
    return (
    <div id = "mainForm">
    <h1 id = "cb_header">Cyberbox Test</h1>
    <LoginForm ChangeRCode = {this.props.PropchangeRCode}/>
    </div>
    )
    }

}