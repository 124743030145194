import React, { useState } from "react";
import { useEffect } from "react";
export const IsusPlayer = (props) =>
{
    const [ch1, setch1] = useState("noone");
    const [b1Class, setb1Class] = useState("");
    const [b2Class, setb2Class] = useState("active-b");
    const [b3Class, setb3Class] = useState("");
    const [abilClass, setabilClass] = useState("super");
    const [isDisabled, setisDisabled] = useState(false);
    useEffect(() => {
        props.CBcolor("#FFFFFF");
        document.body.style.height = "98vh";
    }, []);
    useEffect(() => {
        if(isNaN(props.isChar2))
        setch1(props.isChar2);
        if(props.abilDisabled == false) {
        setabilClass("super")
        }
        else
        {
        setabilClass("super super_g");
        }

    },[props]);
    function clearButtons()
    {
        setb1Class("");
        setb2Class("");
        setb3Class("");
    }
    return(
        <div id="game" className={ch1}>
        <link href="https://trgu.ru/Cyberbox 20775_files/main.bd4d5666.css" rel="stylesheet"></link>
        <div className="bg">
        <img src="https://trgu.ru/assets/bg.png"></img>
        <img src="https://trgu.ru/assets/bg3.png" className="bg2"></img>
        </div>

        <div className="blur">
<div className="character-header">
<h1 id="slov_header">Вы: </h1> 
<div className="avatatar"></div>
</div>


<div className="bars">
    <div className="main-bar">
        <img id="mi" className="mana-icn" src="https://trgu.ru/assets/bars/mana.png"></img>
        <img id="hi" className="heart-icn" src="https://trgu.ru/assets/bars/heart.png"></img>
        <div className="health-bar" id="health">
            <img className="h5" src="https://trgu.ru/assets/bars/health-once.png"></img>
            <img className="h4" src="https://trgu.ru/assets/bars/health-once.png"></img>
            <img className="h3" src="https://trgu.ru/assets/bars/health-once.png"></img>
            <img className="h2" src="https://trgu.ru/assets/bars/health-once.png"></img>
            <img className="h1" src="https://trgu.ru/assets/bars/health-once.png"></img>
        </div>

        <div className="mana-bar" id="mana">
            <img className="m10" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m9" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m8" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m7" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m6" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m5" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m4" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m3" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m2" src="https://trgu.ru/assets/bars/mana-once.png"></img>
            <img className="m1" src="https://trgu.ru/assets/bars/mana-once.png"></img>
        </div>
    </div>
</div>
<div className="divtext_slov"><b>пусай</b></div>
<br /><div className="divtext_slov"><b>0</b> очков</div>

<div className="top-buttons"> 
<div className="super-div"><button className={abilClass} type="button" onClick={() =>{if(!props.abilDisabled) {props.sendMessage("sendtoserver|isus\\buttons\\1");}}}></button></div>
<button className="kulak" type="button" onClick={() =>{props.sendMessage("sendtoserver|isus\\buttons\\2");}}></button>
</div>
<br />
<div className="bottom-buttons">
<button id="butL" className={b1Class} type="button" onClick={(e) =>{props.sendMessage("sendtoserver|isus\\buttons\\3"); clearButtons(); setb1Class("active-b");}}></button>
<button id="butC" className={b2Class} type="button" onClick={(e) =>{props.sendMessage("sendtoserver|isus\\buttons\\4"); clearButtons(); setb2Class("active-b");}}></button>
<button id="butR" className={b3Class} type="button" onClick={(e) =>{props.sendMessage("sendtoserver|isus\\buttons\\5"); clearButtons(); setb3Class("active-b");}}></button>
</div>
<br />
</div>
</div>
    );
}