import React, {useState} from "react";
import { Header } from "../header";

export const TextScreen = (props) =>
{
    return(
    <div id="game">
    <Header/>
<div className ="divtext"><b>{props.PName}</b></div>
<img src = {props.PColors != undefined ? "https://cyberbox.trgu.ru/public/avatars/lobby/PlayerMain" + (props.PColors + 1) +".png": "Аватар загружается\n" }></img><React.Fragment><br/></React.Fragment>
<div className ="divtext"><b>{props.TestText}</b><br /></div>    
    </div>
    );
}