/* eslint-disable react/jsx-pascal-case */
/* eslint-disable default-case */
import React, {useEffect, useState}from "react";
import axios from "axios";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useCookies } from 'react-cookie';

import { FirstLogin } from "./firstLogin";
import { FirstLogin_Code } from "./firstLogin_code";
import { TestPageLogin } from "./firstLogin_testpage";
import {MainGame} from "./maingame.js";

import "../css/app.css";

//const socketUrl = 'wss://cyberbox.trgu.ru';
const ServerURL = 'https://cyberbox.trgu.ru/api';

export const App = () => {
const [RoomCode, setRoomCode] = useState("");
const [PlayerName, setPlayerName] = useState("");
const [PlayerID, setPlayerID] = useState("");
const [ConnectionState, setConnectionState] = useState(0);
const [PlayerPlace, setPlayerPlace] = useState(0);
const [PlayerColor, setPlayerColor] = useState(0);
//const [Stylepath, setStylepath] = useState(0);
const [TestStatus, setTestStatus] = useState(false);

const [cookies, setcookie, removecookie] = useCookies(['nickname', 'lastpid', 'lastrcode, playerplace, playercolor']);

var Backcolor = "black";




const changeBcolor = (Bcolor) =>
{
    document.body.style.backgroundColor = Bcolor;
}
const changeRCode = (RCode, PName) =>
    {
    //setState({RoomCode: RCode, PlayerName: PName}, handleRoomCode);

        setRoomCode(RCode);
        setPlayerName(PName);
        handleRoomCode(RCode, PName);
    }
const handleRoomCode = (RCode, PName) =>
    { 
        setcookie('nickname', PName, { path: '/', maxAge: 34560000000 });
            const json = { code: RCode, nickname: PName };
            if((RCode === cookies['lastrcode']) && (PName === cookies['nickname']))
            axios.get(ServerURL+'/checkplayer?roomcode=' + RCode + '&nickname=' + PName + '&playerid=' + cookies['lastpid'])
                .then(response => handleRejoinWS(response, RCode)).catch((e) => console.log('Error ' + e.response.status + " - " + e.response.data));
            else
            axios.post(ServerURL+'/join', json)
                .then(response => handleWS(response, RCode)).catch((e) => console.log('Error ' + e.response.status + " - " + e.response.data));
        
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        return("");
    }
    const handleRejoinWS = (res, RCode) => 
    {
        if (res.data === "Player is valid")
        {
        }/*
        // cookies.set('name', name, { path: '/' });
        setcookie('nickname', PlayerName, { path: '/' });
        setcookie('lastpid', CID, { path: '/' });
        setcookie('lastrcode', RoomCode, { path: '/' });
        //console.log(p_place);
        //setConnectionState(1);
        handlePID();
        //console.log(CID);
        */
    }

const handleWS = (res, RCode) => 
    {
        let CID = res.data.client_id;
        let p_place = res.data.player_numb;
        let p_color = res.data.color;
        console.log(res);
        setPlayerID(CID);
        setPlayerPlace(p_place);
        setPlayerColor(p_color);
        // cookies.set('name', name, { path: '/' });
        setcookie('nickname', res.data.player, { path: '/', maxAge: 34560000000 });
        setcookie('lastpid', CID, { path: '/', maxAge: 34560000000 });
        setcookie('lastrcode', RCode, { path: '/', maxAge: 34560000000 });
        console.log(cookies);
        console.log(res.data);
        //console.log(p_place);
        //setConnectionState(1);
        handlePID();
        //console.log(CID);
        
    }
const handlePID = () => 
    {
        setConnectionState(1); 
    }
    useEffect(() => {
        document.body.style.backgroundColor = Backcolor;
        // ['nickname', 'lastpid', 'lastrcode, playerplace, playercolor']
        if(cookies['nickname'] === undefined) setcookie('nickname', "", { path: '/', maxAge: 34560000000 });
        if(cookies['lastpid'] === undefined) setcookie('lastpid', "", { path: '/', maxAge: 34560000000  });
        if(cookies['lastrcode'] === undefined) setcookie('lastrcode', "", { path: '/', maxAge: 34560000000  });
        if(cookies['playerplace'] === undefined) setcookie('playerplace', "", { path: '/', maxAge: 34560000000  }); 
        if(cookies['playercolor'] === undefined) setcookie('playercolor', "", { path: '/', maxAge: 34560000000  });
        setcookie('lastrcode', "", { path: '/', maxAge: 34560000000  });
        //console.log(cookies['nickname']);
    });
        
    switch(ConnectionState){
    case 0:
    return (
    <div id="application">
    <Router>
    <Routes>
        <Route path="/" element = {<FirstLogin PropchangeRCode = {changeRCode}/>} />  
        <Route path="/joincode/:roomcode" element = {<FirstLogin_Code PropchangeRCode = {changeRCode}/>} />
        <Route path="/test" element = {<div>Тест</div>}/>
        <Route path="/testpage" element = {<TestPageLogin PropchangeRCode = {changeRCode} setDebug = {setTestStatus}/>} />
    </Routes>
    </Router>
    </div>
    );

    case 1:
        //console.log("State 1");
        return (
        <div id="application">
        <MainGame RCode = {RoomCode} PName = {PlayerName} PID = {PlayerID} PlrPlace = {PlayerPlace} PColor = {PlayerColor} ChangeBColor = {changeBcolor} isDebug = {TestStatus}/>
        </div>
        );
    }

}
